import OneSignal from 'react-onesignal';
import { localStorageKey } from './constants/LocalStorageKey';


export default async function runOneSignal() {
  await OneSignal.init({ appId: 'df835023-76f3-4931-82e5-6f654c96b429', allowLocalhostAsSecureOrigin: true});
  OneSignal.showSlidedownPrompt();
  OneSignal.getUserId().then((userId) => {
   sessionStorage.setItem(localStorageKey.USERID, userId);
  })
}